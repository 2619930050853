
import Vue, { PropType } from "vue";

export default Vue.extend({
  components: {},
  props: {
    pageTitle: {
      type: String,
      default: ""
    },
    hideCurrentPage: {
      type: Boolean,
      default: false
    },
    hideHomePage: {
      type: Boolean,
      default: false
    },
    breadcrumbs: {
      type: Array as PropType<string[]>,
      default(): string[] {
        return [];
      }
    }
  },
  computed: {
    renderBreadcrumbs(): { title: string; link: string }[] {
      const out = [];
      const { pageTitle, hideCurrentPage, hideHomePage } = this;

      if (!hideHomePage) {
        out.push({
          title: "Home",
          link: "/"
        });
      }

      const { breadcrumbs = [] } = this.$route.meta || { breadcrumbs: [] };

      for (const breadcrumb of breadcrumbs) {
        const { link, title } = breadcrumb;
        out.push({ title, link });
      }

      if (!hideCurrentPage) {
        out.push({
          title: pageTitle,
          link: ""
        });
      }

      return out;
    }
  }
});
